import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Preview from './Preview'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    height: '100%'
  }
})

const ImagePreview = ({
  title,
  images,
  subtitle,
  onClick
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Preview
        title={title}
        subtitle={subtitle}
        poster={images[0]}
        size={images.length}
        onClick={onClick}
      />
    </div>
  )
}

export default ImagePreview
