import React, { useState } from 'react'
import { Grid, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Video from '../../../../components/Video'
import ImagePreview from '../../../../components/ImagePreview'
import ImageCarousel from '../../../../components/ImageCarousel'
import Letter from './Letter'

const serviceSubtitles = {
  direct: 'Direktannahme-Protokoll',
  other: 'Service-Information'
}

const salesTitles = {
  new: 'Angebot Neuwagen',
  used: 'Angebot Gebrauchtwagen',
  other: 'Angebot'
}

const salesSubtitles = {
  introduction: 'Vorstellung',
  sale: 'Verkauf'
}

const useStyles = makeStyles({
  modal: {
    width: '80%',
    height: '60%',
    right: '10%',
    position: 'absolute',
    backgroundColor: 'white'
  }
})

const Teaser = ({
  vehicle,
  purpose,
  section,
  orderId,
  videoId,
  preview,
  videoPath,
  posterPath,
  posterFallback,
  letterText,
  customer,
  user,
  images,
  alignedWidth = false
}) => {
  let videoTitle
  let videoSubtitle

  const [showModal, setShowModal] = useState()

  if (section === 'service') {
    videoTitle = vehicle.id
    videoSubtitle = serviceSubtitles[purpose] || serviceSubtitles['other']
  } else {
    videoTitle = salesTitles[vehicle.condition] || salesTitles['other']
    videoSubtitle = salesSubtitles[purpose] || salesSubtitles['other']
  }

  const showImageModal = evt => {
    evt.preventDefault()
    setShowModal(true)
  }

  const hideImageModal = evt => {
    evt.preventDefault()
    setShowModal(false)
  }

  const content = (
    <Grid
      container
      component='section'
      alignItems='stretch'
    >
      <Grid item xs={12} md={6}>
        {(images && images.length > 0) &&
          <ImagePreview
            images={images}
            title={videoTitle}
            subtitle={videoSubtitle}
            onClick={showImageModal}
          />
          ||
          <Video
            orderId={orderId}
            videoId={videoId}
            videoPath={videoPath}
            posterPath={posterPath}
            preview={preview}
            posterFallback={posterFallback}
            title={videoTitle}
            subtitle={videoSubtitle}
          />
        }
      </Grid>
      <Grid item xs={12} md={6}>
        <Letter
          text={letterText}
          customer={customer}
          user={user}
        />
      </Grid>
      {showModal &&
        <ImageCarousel
          images={images}
          onClose={hideImageModal}
        />
      }
    </Grid>
  )

  if (alignedWidth) {
    return (
      <Container>
        {content}
      </Container>
    )
  }

  return content
}

export default Teaser
