import React, { useState } from 'react'
import { Grid, Container, Box, Typography, Paper } from '@material-ui/core'
import Documents from './Documents'
import Video from '../../../components/Video'
import ImagePreview from '../../../components/ImagePreview'
import ImageCarousel from '../../../components/ImageCarousel'
import Letter from './Letter'
import { makeStyles } from '@material-ui/styles'
import Summary from './Summary'
import Appointment from './Appointment'
import { CheckCircle } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: '#fff'
  },
  heading: {
    display: 'flex',
    alignItems: 'center'
  },
  check: {
    marginRight: '0.5em',
    color: theme.palette.common.signal,
    fontSize: '1.9em'
  },
  modal: {
    width: '80%',
    height: '60%',
    right: '10%',
    position: 'absolute',
    backgroundColor: 'white'
  }
}))

const Teaser = ({
  orderId,
  images,
  orderPdfs,
  orderDocuments,
  videoPath,
  posterPath,
  posterFallback,
  customer,
  user,
  orderNumber,
  appointment,
  preview,
  licencePlateNumber,
  totalCosts,
  confirmationText,
  currency,
  defects,
  letter
}) => {
  const classes = useStyles()
  const [showModal, setShowModal] = useState()

  const showImageModal = evt => {
    evt.preventDefault()
    setShowModal(true)
  }

  const hideImageModal = evt => {
    evt.preventDefault()
    setShowModal(false)
  }

  return (
    <Container component='section'>
      <Box my={5}>
        <Paper>
          <Grid
            container
            component='section'
            alignItems='stretch'
          >
            <Grid item xs={12} md={6}>
              <Container className={classes.content}>
                <Typography variant='h5' className={classes.heading}>
                  <CheckCircle className={classes.check} />
                  Auftrag bestätigt!
                </Typography>
                <Summary
                  orderNumber={orderNumber}
                  licencePlateNumber={licencePlateNumber}
                  totalCosts={totalCosts}
                  currency={currency}
                  defects={defects}
                  letter={letter}
                />
                {(orderNumber.startsWith('WV') && appointment) && (
                  <Appointment appointment={appointment} />
                )}
                <Letter
                  confirmationText={confirmationText}
                  customer={customer}
                  user={user}
                />
                <Documents
                  pdfs={orderPdfs}
                  documents={orderDocuments}
                  orderId={orderId}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              {(images && images.length > 0) &&
                <ImagePreview
                  images={images}
                  onClick={showImageModal}
                />
                ||
                <Video
                  orderId={orderId}
                  videoPath={videoPath}
                  posterPath={posterPath}
                  preview={preview}
                  posterFallback={posterFallback}
                />
              }
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {showModal &&
        <ImageCarousel
          images={images}
          onClose={hideImageModal}
        />
      }
    </Container>
  )
}

export default Teaser
