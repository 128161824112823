import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Close, NavigateNext, NavigateBefore } from '@material-ui/icons'

const useStyles = makeStyles({
  root: {
    width: '80%',
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
  closeButton: {
    top: 0,
    right: 0,
    color: 'white',
    fontSize: '42px',
    cursor: 'pointer',
    position: 'absolute',
    borderTopRightRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  },
  nextButton: {
    right: 0,
    color: 'white',
    fontSize: '64px',
    cursor: 'pointer',
    position: 'absolute',
    top: 'calc(50% - 32px)',
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  },
  beforeButton: {
    left: 0,
    color: 'white',
    fontSize: '64px',
    cursor: 'pointer',
    position: 'absolute',
    top: 'calc(50% - 32px)',
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  },
  image: {
    width: '100%',
    borderRadius: '20px'
  },
  imageNumberIndicator: {
    width: '100px',
    padding: '4px',
    color: 'white',
    fontSize: '20px',
    marginTop: '-50px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  }
})

const ImageCarousel = ({
  images,
  onClose
}) => {
  const classes = useStyles()
  const [imageIndex, setImageIndex] = useState()

  if (imageIndex == undefined) {
    setImageIndex(0)
  }

  const navigateNext = evt => {
    evt.preventDefault()
    setImageIndex(imageIndex < (images.length - 1) && (imageIndex + 1) || 0)
  }

  const navigateBefore = evt => {
    evt.preventDefault()
    imageIndex > 0 ? setImageIndex(imageIndex - 1) : setImageIndex(images.length - 1)
  }

  return (
    <div className={classes.root}>
      <Close className={classes.closeButton} onClick={onClose} />
      <NavigateNext className={classes.nextButton} onClick={navigateNext} />
      <NavigateBefore className={classes.beforeButton} onClick={navigateBefore} />
      <img src={images[imageIndex || 0]} className={classes.image} />
      <div className={classes.imageNumberIndicator}>
        {imageIndex + 1} von {images.length}
      </div>
    </div>
  )
}

export default ImageCarousel
